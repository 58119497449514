/* 2 Layout
----------------------------------------------------------------------------- */
.page-wrapper {
	background-color: #ffffff;
}
.header-top:not(.banner) {
	background-color: #f2f2f2;
	border-bottom: 1px solid #d0d0d0;
	padding: 4px 0;
}
.header-top.dark {
	background-color: @dark-header-top;
	border-bottom: 1px solid @dark-header-top-border;
}
.header-top.colored {
	background-color: @base-color;
	border-bottom: 1px solid @base-color;
}
.header {
	border-top: 1px solid #ffffff;
	border-bottom: 2px solid #f1f1f1;
	background-color: #fcfcfc;
}
.header.dark {
	background-color: @dark-header;
	border-top-color: @dark-header;
	border-bottom: none;
}
.header-top.dark + .header:not(.dark) { 
	border-top-color: #dddddd;
}
.header-top:not(.dark) + .header.dark {
	border-top-color: #303030;
}
.header.header-small {
	padding: 10px 0;
}
.dark {
	.footer {
		background-color: #222222;
	}
	.subfooter {
		background-color: #373737;
		border-color: #454545;
	}
}
/*Transparent Header*/
.transparent-header .header-top {
	z-index: 103;
	position: relative;
}
.transparent-header header.header.transparent-header-on {
	z-index: 22;
	position: absolute;
	width: 100%;
	left: 0;
	background-color: rgba(255, 255, 255, 0.92);
	border-bottom: 1px solid rgba(0, 0, 0, 0.07);
	border-top-color: #a0a0a0;
}
.transparent-header .header-top.colored + header.header.transparent-header-on {
	border-top-color: transparent;
}
.transparent-header  header.header.dark.transparent-header-on {
	background-color: @dark-header-opacity-80;
	border-top-color: @dark-header-top-opacity-60;
}
.header-left {
	padding: 12px 0 0 0;
}
.header-right {
	position: relative;
}
.banner.parallax,
.banner.light-translucent-bg,
.banner.dark-translucent-bg,
.banner.default-translucent-bg {
	min-height: 250px;
	padding-top: 60px;
	padding-bottom: 20px;
}
.banner.banner-big-height {
	min-height: 550px;
	padding-top: 100px;
}
.banner.video-background-banner {
	min-height: 400px;
	padding-top: 100px;
}
.main-container {
	padding: 50px 0;
}
.block {
	margin-bottom: 50px;
}
.section {
	padding: 20px 0;
}

.footer-top {
	padding: 20px 0;
}

.footer {
	padding: 40px 0;
	background-color: #fafafa;
}

.footer-content {
	padding: 20px 0;
}

.subfooter {
	background-color: #f5f5f5;
	padding: 25px 0;
	border-top: 1px solid #eaeaea;	
}
/* Small devices (tablets, 768px and up) */
@media (min-width:768px) {
	.with-dropdown-buttons {
		.navbar-default .navbar-collapse {
			padding-right: 120px;
		}
	 	.header-dropdown-buttons {
			position: absolute;
			top: 18px;
			right: 0px;
		}
	}
	/*Full width Header*/	
	header.full-width .with-dropdown-buttons {
	 	.header-dropdown-buttons {
			right: 15px;
		}
	}
	/*Centered Logo Layout*/
	.header.centered .header-left {
		padding: 12px 0;
	}
	.header.centered .header-right {
		text-align: center;
	}
	.header.centered .main-navigation .navbar-collapse.collapse {
		display: inline-block!important;
		vertical-align: top;
	}	
	.header.centered .with-dropdown-buttons {
		.navbar-default .navbar-collapse {
			padding-right: 0px!important;
		}
	 	.header-dropdown-buttons {
			position: relative;
			top: 4px;
			margin-left: 20px;
			float: left;
		}
	}
}
/* Large and Medium devices (desktops, tablets less than 1199px) */
@media (min-width:768px) and (max-width:1199px) {
	.main-navigation.with-dropdown-buttons {
		.navbar-default .navbar-collapse {
			padding-right: 110px;
		}
	}
}
/* Medium desktop devices and tablets */
@media (min-width: 768px) and (max-width: 991px) { 
	header.full-width .main-navigation.with-dropdown-buttons .navbar-default .navbar-collapse {
		padding-right: 15px;
	}
}
/* Medium devices (desktops, tablets less than 991px) */
@media (max-width: 991px) {
	.sidebar {
		margin-top: 40px;
	}
	.footer-content {
		padding: 10px 0;
	}
}
/* Small devices (tablets, phones less than 767px) */
@media (max-width: 767px) {
	/*Transparent Header*/
	.transparent-header .header-left {
		padding: 12px 0 12px;
	}
	.transparent-header .header-right {
		z-index: 21;
	}
	/*Full width Header*/
	.header.full-width {
		padding: 0 15px;
	}
	.header.full-width .header-left {
		padding: 8px 0 0;
	}
}
/* Boxed Layout
---------------------------------- */
.boxed .page-wrapper {
	margin-right: auto;
	margin-left: auto;
	position: relative;
	-webkit-box-shadow: 0px 0px 10px #cacaca;;
	box-shadow: 0px 0px 10px #cacaca;
}
.boxed.transparent-page-wrapper .page-wrapper {
	background-color: transparent;
	-webkit-box-shadow: none;
	box-shadow: none;
}
.pattern-1 { 
	background-image: url("../images/pattern-1.png");
	background-repeat: repeat;
	background-position: 0 0; 
}
.pattern-2 { 
	background-image: url("../images/pattern-2.png");
	background-repeat: repeat;
	background-position: 0 0; 
}
.pattern-3 { 
	background-image: url("../images/pattern-3.png");
	background-repeat: repeat;
	background-position: 0 0; 
}
.pattern-4 { 
	background-image: url("../images/pattern-4.png");
	background-repeat: repeat;
	background-position: 0 0; 
}
.pattern-5 { 
	background-image: url("../images/pattern-5.png");
	background-repeat: repeat;
	background-position: 0 0; 
}
.pattern-6 { 
	background-image: url("../images/pattern-6.png");
	background-repeat: repeat;
	background-position: 0 0; 
}
.pattern-7 { 
	background-image: url("../images/pattern-7.png");
	background-repeat: repeat;
	background-position: 0 0; 
}
.pattern-8 { 
	background-image: url("../images/pattern-8.png");
	background-repeat: repeat;
	background-position: 0 0; 
}
.pattern-9 { 
	background-image: url("../images/pattern-9.png");
	background-repeat: repeat;
	background-position: 0 0; 
}
.pattern-4 .page-wrapper,
.pattern-5 .page-wrapper,
.pattern-6 .page-wrapper,
.pattern-7 .page-wrapper,
.pattern-8 .page-wrapper,
.pattern-9 .page-wrapper { 
	-webkit-box-shadow: none;
	box-shadow: none;
}
/* Small devices (tablets, 768px and up) */
@media (min-width:768px) {
	.boxed .page-wrapper {
		width: 750px;
	}
}
/* Medium devices (desktops, tablets, 992px and up) */
@media (min-width:992px) {
	.boxed .page-wrapper {
		width: 970px;
	}
}
/* Large devices (Large desktops 1200px and up) */
@media (min-width:1200px) {
	.boxed .page-wrapper {
		width: 1170px;
	}
}
/*Backgrounds
---------------------------------- */
.background-img-1 { 
	background: url("../images/background-img-1.jpg") 50% 0px no-repeat;
}
.background-img-2 { 
	background: url("../images/background-img-2.jpg") 50% 0px no-repeat;
}
.background-img-3 { 
	background: url("../images/background-img-3.jpg") 50% 0px no-repeat;
}
.background-img-4 { 
	background: url("../images/background-img-4.jpg") 50% 0px no-repeat;
}
.background-img-5 { 
	background: url("../images/background-img-5.jpg") 50% 0px no-repeat;
}
.background-img-6 { 
	background: url("../images/background-img-6.jpg") 50% 0px no-repeat;
}
.background-img-7 { 
	background: url("../images/background-img-7.jpg") 50% 0px no-repeat;
}
.background-img-8 { 
	background: url("../images/background-img-8.jpg") 50% 0px no-repeat;
}
.background-img-9 { 
	background: url("../images/background-img-9.jpg") 50% 0px no-repeat;
}
.background-img-10 { 
	background: url("../images/background-img-10.jpg") 50% 0px no-repeat;
}
.fullscreen-bg {
	background: url("../images/fullscreen-bg.jpg") 50% 0px no-repeat;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}
.boxed .fullscreen-bg {
	background: none;
}
.white-bg {
	background-color: #ffffff;
}
.light-gray-bg {
	background-color: #fafafa;
	border-top: 1px solid #f3f3f3;
	border-bottom: 1px solid #f3f3f3;
}
.footer-top.light-gray-bg { 
	background-color: #f5f5f5;
	border-top: 1px solid #eaeaea;
	border-bottom: 1px solid #eaeaea;
}
.dark-bg {
	background-color: #373737;
	color: #cdcdcd;
}
.dark-bg + .dark-bg:not(.image-box):not(.full-image-container):not(.full-text-container):not(.full-width-section) {
	background-color: #555555;
}
.dark-bg + .dark-bg + .dark-bg {
	background-color: #373737;
}
.dark-bg h1,
.dark-bg h2,
.dark-bg h3,
.dark-bg h4,
.dark-bg h5,
.dark-bg h6 {
	color: #ffffff;
}
.dark-bg a:not(.btn) {
	text-decoration: underline;
	color: #cdcdcd;
}
.dark-bg a:not(.btn):hover {
	text-decoration: none;
	color: #cdcdcd;
}
.default-bg {
	background-color:@base-color;
	color: #ffffff;
}
.default-bg h1,
.default-bg h2,
.default-bg h3,
.default-bg h4,
.default-bg h5,
.default-bg h6 {
	color: #ffffff;
}
.default-bg a:not(.btn) {
	text-decoration: underline;
	color: #ffffff;
}
.default-bg a:not(.btn):hover {
	text-decoration: none;
	color: #ffffff;
}

/*Translucent Backgrounds*/
.no-touch .parallax,
.no-touch .parallax-2,
.no-touch .parallax-3,
.no-touch .fixed-bg {
	background-attachment: fixed;
}
.light-translucent-bg,
.dark-translucent-bg,
.default-translucent-bg,
.two-col-translucent-bg {
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-position: 50% 0;
	background-repeat: no-repeat;
	z-index: 1;
	position: relative;
	overflow: hidden;
}
.light-translucent-bg:after,
.dark-translucent-bg:after,
.default-translucent-bg:after,
.two-col-translucent-bg:after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	width: 100%;
	height: 100%;
	.transition(all 0.2s ease-in-out);
	-webkit-backface-visibility: hidden;
	-webkit-transform: translateZ(0) scale(1.0, 1.0);	
}
.light-translucent-bg > div,
.dark-translucent-bg > div,
.default-translucent-bg > div,
.two-col-translucent-bg > div {
	z-index: 3;
	position: relative;
}
.light-translucent-bg {
	color: #333;
	border-bottom: 1px solid #dadada;
	border-top: 1px solid #dadada;
}
.light-translucent-bg:after {
	background-color: rgba(255, 255, 255, 0.7);
}
.light-translucent-bg.slight:after {
	background-color: rgba(255, 255, 255, 0.85);
}
.video-background.light-translucent-bg:after,
.video-background-banner.light-translucent-bg:after {
	background-color: rgba(255, 255, 255, 0.5);	
}
.light-translucent-bg.hovered:hover:after {
	background-color: rgba(255, 255, 255, 0.8);
}
.dark-translucent-bg:after {
	background-color: rgba(0, 0, 0, 0.5);
}
.dark-translucent-bg.hovered:hover:after {
	background-color: rgba(0, 0, 0, 0.6);
}
.dark-translucent-bg,
.dark-translucent-bg h1,
.dark-translucent-bg h2,
.dark-translucent-bg h3,
.dark-translucent-bg h4,
.dark-translucent-bg h5,
.dark-translucent-bg h6 {
	color: #ffffff;
}
.dark-translucent-bg a:not(.btn) {
	text-decoration: underline;
	color: #ffffff;
}
.dark-translucent-bg a:not(.btn):hover {
	text-decoration: none;
	color: #ffffff;
}
.two-col-translucent-bg { 
	color: #333;
}
.two-col-translucent-bg:after { 
	background-color: rgba(255, 255, 255, 0.7);
}
/* Medium devices (desktops, tablets, 992px and up) */
@media (min-width: 992px) { 
	.two-col-translucent-bg:after {
		background: -moz-linear-gradient(left,rgba(255, 255, 255, 0.7) 50%,rgba(0, 0, 0, 0.5) 50%);
		background: -webkit-gradient(left,rgba(255, 255, 255, 0.7) 50%,rgba(0, 0, 0, 0.5) 50%);
		background: -webkit-linear-gradient(left,rgba(255, 255, 255, 0.7) 50%,rgba(0, 0, 0, 0.5) 50%);
		background: -o-linear-gradient(left,rgba(255, 255, 255, 0.7) 50%,rgba(0, 0, 0, 0.5) 50%);
		background: -ms-linear-gradient(left,rgba(255, 255, 255, 0.7) 50%,rgba(0, 0, 0, 0.5) 50%);
		background: linear-gradient(to right,rgba(255, 255, 255, 0.7) 50%,rgba(0, 0, 0, 0.5) 50%);
	}
	.two-col-translucent-bg .container > .row > div + div,
	.two-col-translucent-bg .container > .row > div + div h1,
	.two-col-translucent-bg .container > .row > div + div h2,
	.two-col-translucent-bg .container > .row > div + div h3,
	.two-col-translucent-bg .container > .row > div + div h4,
	.two-col-translucent-bg .container > .row > div + div h5,
	.two-col-translucent-bg .container > .row > div + div h6 {
		color: #ffffff;
	}
	.two-col-translucent-bg .container > .row > div + div a:not(.btn) {
		text-decoration: underline;
		color: #ffffff;
	}
	.two-col-translucent-bg .container > .row > div + div a:not(.btn):hover {
		text-decoration: none;
		color: #ffffff;
	}
}
.default-translucent-bg:after {
	background-color: rgba(9, 175, 223, 0.7);
}
.default-translucent-bg.hovered:hover:after {
	background-color: rgba(9, 175, 223, 0.8);
}
.default-translucent-bg,
.default-translucent-bg h1,
.default-translucent-bg h2,
.default-translucent-bg h3,
.default-translucent-bg h4,
.default-translucent-bg h5,
.default-translucent-bg h6 {
	color: #ffffff;
}
.default-translucent-bg a:not(.btn) {
	text-decoration: underline;
	color: #ffffff;
}
.default-translucent-bg a:not(.btn):hover {
	text-decoration: none;
	color: #ffffff;
}
.default-hovered {
	.transition(all 0.2s ease-in-out);
}
.default-hovered:hover:after,
.default-hovered:hover {
	background-color: @base-color;
}
.light-gray-bg.default-hovered:hover * {
	color: #ffffff;
}
.position-bottom { 
	position: absolute!important;
	bottom: 0;
	width: 100%;
}
/*Animated Backgrounds
---------------------------------- */
/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) { 
	.animated-text h2 {
		.transition(all 0.2s ease-in-out);
		position: absolute;
		top: 0;
		width: 100%;
	}
	.animated-text h2 + h2 {
		.opacity(0);
		.scale(1.15);
	}
	.animated-text:hover h2 {
		.opacity(0);
		.scale(0.85);
	}
	.animated-text:hover h2 + h2 {
		.opacity(1);
		.scale(1);
	}
}
/* Small devices (tablets, phones less than 767px) */
@media (max-width: 767px) {
	.animated-text h2 + h2 { 
		display: none;
	}
}
/* Fixed navigation
---------------------------------- */
.fixed-header-on .header.fixed,
.fixed-header-on header.header.transparent-header-on {
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 1006;
	.opacity(0);
	-webkit-backface-visibility:hidden; 
	-webkit-transform: translateZ(0);
}
.fixed-header-on .header.fixed:not(.dark) {
	border-bottom: 1px solid #f1f1f1;
	background-color: rgba(255, 255, 255, 0.95);
}
.fixed-header-on .dark.header.fixed {
	background-color: @dark-header-opacity-95;
}
.fixed-header-on.boxed .header.fixed {
	left: auto;
}
.fixed-header-on .header .logo {
	margin: 0px 0 -5px 0;
	display: none;
}
.fixed-header-on .header .logo img { 
	/*max-height: 30px;*/
}
.fixed-header-on .header .site-slogan,
.fixed-header-on .header.centered .header-left {
	display: none;
}
.fixed-header-on .header.fixed.animated {
	-webkit-animation-duration: 0.4s;
	animation-duration: 0.4s;
}
/* Small devices (tablets, 768px and up) */
@media (min-width:768px) {
	.fixed-header-on.boxed .header.fixed {
		width: 750px;
	}
	.fixed-header-on .header-left {
		padding: 0;
	}
	.fixed-header-on .dark.header .dropdown-menu {
		border: 1px solid @dark-header-top;
	}
	.fixed-header-on .main-navigation .navbar-nav > li > a {
		padding-top: 21px!important;
		padding-bottom: 21px!important;
	}
	.fixed-header-on .header:not(.centered) .main-navigation .navbar-nav > .dropdown > a:before {
		top: 24px;
	}
	.fixed-header-on .header:not(.centered) .main-navigation.with-dropdown-buttons .header-dropdown-buttons {
		top: 12px;
	}
	.fixed-header-on .header-dropdown-buttons .btn-group .dropdown-menu,
	.fixed-header-on .dark .header-dropdown-buttons .btn-group .dropdown-menu {
		margin-top: 11px;
	}
	/*Fixed Header Animations Duration*/
	.fixed.header .main-navigation.animated .navbar-nav > li > a,
	.header-left {
		-webkit-transition: padding 0s linear, color 0.2s ease-in-out, background-color 0.2s ease-in-out;
		-o-transition: padding 0s linear, color 0.2s ease-in-out, background-color 0.2s ease-in-out;
		transition: padding 0s linear, color 0.2s ease-in-out, background-color 0.2s ease-in-out;
	}
}

/* Medium devices (desktops, tablets, 992px and up) */
@media (min-width:992px) {
	.fixed-header-on .header .logo,
	.fixed-header-on .header .site-slogan {
		display: block;
	}
	.fixed-header-on.boxed .header.fixed {
		width: 970px;
	}
	.fixed-header-on .header-left {
		padding: 7px 0 0 0;
	}
}

/* Large devices (Large desktops 1200px and up) */
@media (min-width:1200px) {
	.fixed-header-on.boxed .header.fixed {
		width: 1170px;
	}
}

/* Small devices (tablets, phones less than 767px) */
@media (max-width:767px) {
	/* Fixed header position */
	.header.fixed {
		position: relative !important;
		top: auto !important;
	}
	.header.fixed.transparent-header-on {
		position: absolute!important;
	}
}

/* Custom Grid
---------------------------------- */
.grid-space-20 {
	margin-right: -10.5px;
	margin-left: -10.5px;
}
.grid-space-20 .col-lg-6,
.grid-space-20 .col-lg-4,
.grid-space-20 .col-lg-3,
.grid-space-20 .col-md-6,
.grid-space-20 .col-md-4,
.grid-space-20 .col-md-3,
.grid-space-20 .col-sm-6,
.grid-space-20 .col-sm-4,
.grid-space-20 .col-sm-3,
.grid-space-20 .col-xs-6,
.grid-space-20 .col-xs-4,
.grid-space-20 .col-xs-3 {
	padding-left: 10px;
	padding-right: 10px;
}
.grid-space-10 {
	margin-right: -6px;
	margin-left: -6px;
}
.grid-space-10 .col-lg-6,
.grid-space-10 .col-lg-4,
.grid-space-10 .col-lg-3,
.grid-space-10 .col-md-6,
.grid-space-10 .col-md-4,
.grid-space-10 .col-md-3,
.grid-space-10 .col-sm-6,
.grid-space-10 .col-sm-4,
.grid-space-10 .col-sm-3,
.grid-space-10 .col-xs-6,
.grid-space-10 .col-xs-4,
.grid-space-10 .col-xs-3 {
	padding-left: 5px;
	padding-right: 5px;
}
.grid-space-0 {
	margin-right: 0;
	margin-left: 0;
}
.grid-space-0 .col-lg-6,
.grid-space-0 .col-lg-4,
.grid-space-0 .col-lg-3,
.grid-space-0 .col-md-6,
.grid-space-0 .col-md-4,
.grid-space-0 .col-md-3,
.grid-space-0 .col-sm-6,
.grid-space-0 .col-sm-4,
.grid-space-0 .col-sm-3,
.grid-space-0 .col-xs-6,
.grid-space-0 .col-xs-4,
.grid-space-0 .col-xs-3 {
	padding-left: 0;
	padding-right: 0;
}
.grid-space-0>div {
	margin-bottom: 0;
	padding-bottom: 0;
}

/* Separators
---------------------------------- */
.separator {
	width: 100%;
	margin: 20px auto 15px;
	position: relative;
	height: 1px;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1, 1);	
}
.separator:after {
	height: 1px;
	background: #e8e8e8;
	background: -moz-linear-gradient(left,  rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.09) 35%, rgba(0, 0, 0, 0.09) 70%, rgba(0, 0, 0, 0) 100%);
	background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(0, 0, 0, 0)), color-stop(35%,rgba(0, 0, 0, 0.09)), color-stop(70%,rgba(0, 0, 0, 0.09)), color-stop(100%,rgba(0, 0, 0, 0)));
	background: -webkit-linear-gradient(left,  rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 0.09) 35%,rgba(0, 0, 0, 0.09) 70%,rgba(0, 0, 0, 0) 100%);
	background: -o-linear-gradient(left,  rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 0.09) 35%,rgba(0, 0, 0, 0.09) 70%,rgba(0, 0, 0, 0) 100%);
	background: -ms-linear-gradient(left,  rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 0.09) 35%,rgba(0, 0, 0, 0.09) 70%,rgba(0, 0, 0, 0) 100%);
	background: linear-gradient(to right,  rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 0.09) 35%,rgba(0, 0, 0, 0.09) 70%,rgba(0, 0, 0, 0) 100%);
	position: absolute;
	bottom: -1px;
	content: "";
	width: 100%;
	left: 0;
}
.dark-bg:not(.banner) .separator:after {
	background: #505050;
	background: -moz-linear-gradient(left,  #373737 0%, #505050 35%, #505050 70%, #373737 100%);
	background: -webkit-gradient(linear, left top, right top, color-stop(0%,#373737), color-stop(35%,#505050), color-stop(70%,#505050), color-stop(100%,#373737));
	background: -webkit-linear-gradient(left,  #373737 0%,#505050 35%,#505050 70%,#373737 100%);
	background: -o-linear-gradient(left,  #373737 0%,#505050 35%,#505050 70%,#373737 100%);
	background: -ms-linear-gradient(left,  #373737 0%,#505050 35%,#505050 70%,#373737 100%);
	background: linear-gradient(to right,  #373737 0%,#505050 35%,#505050 70%,#373737 100%);
}
#footer.dark .separator:after {
	background: #505050;
	background: -moz-linear-gradient(left,  #222222 0%, #505050 35%, #505050 70%, #222222 100%);
	background: -webkit-gradient(linear, left top, right top, color-stop(0%,#222222), color-stop(35%,#505050), color-stop(70%,#505050), color-stop(100%,#222222));
	background: -webkit-linear-gradient(left,  #222222 0%,#505050 35%,#505050 70%,#222222 100%);
	background: -o-linear-gradient(left,  #222222 0%,#505050 35%,#505050 70%,#222222 100%);
	background: -ms-linear-gradient(left,  #222222 0%,#505050 35%,#505050 70%,#222222 100%);
	background: linear-gradient(to right,  #222222 0%,#505050 35%,#505050 70%,#222222 100%);
}
.default-bg .separator:after,
.dark-translucent-bg .separator:after,
.default-translucent-bg .separator:after,
.light.separator:after {
	background: rgba(255, 255, 255, 0.5);
	background: -moz-linear-gradient(left,  rgba(255, 255, 255, 0.0) 0%, rgba(255, 255, 255, 0.5) 35%, rgba(255, 255, 255, 0.5) 70%, rgba(255, 255, 255, 0.0) 100%);
	background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255, 255, 255, 0.0)), color-stop(35%,rgba(255, 255, 255, 0.5)), color-stop(70%,rgba(255, 255, 255, 0.5)), color-stop(100%,rgba(255, 255, 255, 0.0)));
	background: -webkit-linear-gradient(left,  rgba(255, 255, 255, 0.0) 0%,rgba(255, 255, 255, 0.5) 35%,rgba(255, 255, 255, 0.5) 70%,rgba(255, 255, 255, 0.0) 100%);
	background: -o-linear-gradient(left,  rgba(255, 255, 255, 0.0) 0%,rgba(255, 255, 255, 0.5) 35%,rgba(255, 255, 255, 0.5) 70%,rgba(255, 255, 255, 0.0) 100%);
	background: -ms-linear-gradient(left,  rgba(255, 255, 255, 0.0) 0%,rgba(255, 255, 255, 0.5) 35%,rgba(255, 255, 255, 0.5) 70%,rgba(255, 255, 255, 0.0) 100%);
	background: linear-gradient(to right,  rgba(255, 255, 255, 0.0) 0%,rgba(255, 255, 255, 0.5) 35%,rgba(255, 255, 255, 0.5) 70%,rgba(255, 255, 255, 0.0) 100%);
}
/* Medium devices (desktops, tablets, 992px and up) */
@media (min-width: 992px) { 
	.two-col-translucent-bg .container > .row > div + div .separator:after {
		background: rgba(255, 255, 255, 0.5);
		background: -moz-linear-gradient(left,  rgba(255, 255, 255, 0.0) 0%, rgba(255, 255, 255, 0.5) 35%, rgba(255, 255, 255, 0.5) 70%, rgba(255, 255, 255, 0.0) 100%);
		background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255, 255, 255, 0.0)), color-stop(35%,rgba(255, 255, 255, 0.5)), color-stop(70%,rgba(255, 255, 255, 0.5)), color-stop(100%,rgba(255, 255, 255, 0.0)));
		background: -webkit-linear-gradient(left,  rgba(255, 255, 255, 0.0) 0%,rgba(255, 255, 255, 0.5) 35%,rgba(255, 255, 255, 0.5) 70%,rgba(255, 255, 255, 0.0) 100%);
		background: -o-linear-gradient(left,  rgba(255, 255, 255, 0.0) 0%,rgba(255, 255, 255, 0.5) 35%,rgba(255, 255, 255, 0.5) 70%,rgba(255, 255, 255, 0.0) 100%);
		background: -ms-linear-gradient(left,  rgba(255, 255, 255, 0.0) 0%,rgba(255, 255, 255, 0.5) 35%,rgba(255, 255, 255, 0.5) 70%,rgba(255, 255, 255, 0.0) 100%);
		background: linear-gradient(to right,  rgba(255, 255, 255, 0.0) 0%,rgba(255, 255, 255, 0.5) 35%,rgba(255, 255, 255, 0.5) 70%,rgba(255, 255, 255, 0.0) 100%);
	}
}
.light-translucent-bg .separator:after,
.two-col-translucent-bg .container > .row > div .separator:after,
.dark.separator:after {
	background: rgba(0, 0, 0, 0.5);
	background: -moz-linear-gradient(left,  rgba(0, 0, 0, 0.0) 0%, rgba(0, 0, 0, 0.4) 35%, rgba(0, 0, 0, 0.4) 70%, rgba(0, 0, 0, 0.0) 100%);
	background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(0, 0, 0, 0.0)), color-stop(35%,rgba(0, 0, 0, 0.4)), color-stop(70%,rgba(0, 0, 0, 0.4)), color-stop(100%,rgba(0, 0, 0, 0.0)));
	background: -webkit-linear-gradient(left,  rgba(0, 0, 0, 0.0) 0%,rgba(0, 0, 0, 0.4) 35%,rgba(0, 0, 0, 0.4) 70%,rgba(0, 0, 0, 0.0) 100%);
	background: -o-linear-gradient(left,  rgba(0, 0, 0, 0.0) 0%,rgba(0, 0, 0, 0.4) 35%,rgba(0, 0, 0, 0.4) 70%,rgba(0, 0, 0, 0.0) 100%);
	background: -ms-linear-gradient(left,  rgba(0, 0, 0, 0.0) 0%,rgba(0, 0, 0, 0.4) 35%,rgba(0, 0, 0, 0.4) 70%,rgba(0, 0, 0, 0.0) 100%);
	background: linear-gradient(to right,  rgba(0, 0, 0, 0.0) 0%,rgba(0, 0, 0, 0.4) 35%,rgba(0, 0, 0, 0.4) 70%,rgba(0, 0, 0, 0.0) 100%);
}
h3 + .separator, 
h4 + .separator, 
h5 + .separator { 
	margin-top: 15px;
}
.separator-2,
.separator-3 {
	width: 100%;
	margin-bottom: 15px;
	position: relative;
	height: 1px;
}
.separator-2:after {
	height: 1px;
	background: #d1d1d1;
	background: -moz-linear-gradient(left,  rgba(0, 0, 0, 0.18) 0%, rgba(0, 0, 0, 0.12) 35%, rgba(0, 0, 0, 0.05) 70%, rgba(0, 0, 0, 0) 100%);
	background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(0, 0, 0, 0.18)), color-stop(35%,rgba(0, 0, 0, 0.12)), color-stop(70%,rgba(0, 0, 0, 0.05)), color-stop(100%,rgba(0, 0, 0, 0)));
	background: -webkit-linear-gradient(left,  rgba(0, 0, 0, 0.18) 0%,rgba(0, 0, 0, 0.12) 35%,rgba(0, 0, 0, 0.05) 70%,rgba(0, 0, 0, 0) 100%);
	background: -o-linear-gradient(left,  rgba(0, 0, 0, 0.18) 0%,rgba(0, 0, 0, 0.12) 35%,rgba(0, 0, 0, 0.05) 70%,rgba(0, 0, 0, 0) 100%);
	background: -ms-linear-gradient(left,  rgba(0, 0, 0, 0.18) 0%,rgba(0, 0, 0, 0.12) 35%,rgba(0, 0, 0, 0.05) 70%,rgba(0, 0, 0, 0) 100%);
	background: linear-gradient(to right,  rgba(0, 0, 0, 0.18) 0%,rgba(0, 0, 0, 0.12) 35%,rgba(0, 0, 0, 0.05) 70%,rgba(0, 0, 0, 0) 100%);
	position: absolute;
	bottom: -1px;
	left: 0px;
	content: "";
	width: 100%;
}
.dark-bg .separator-2:after {
	background: #666666;
	background: -moz-linear-gradient(left,  #666666 0%, #555555 35%, #444444 70%, #373737 100%);
	background: -webkit-gradient(linear, left top, right top, color-stop(0%,#666666), color-stop(35%,#555555), color-stop(70%,#444444), color-stop(100%,#373737));
	background: -webkit-linear-gradient(left,  #666666 0%,#555555 35%,#444444 70%,#373737 100%);
	background: -o-linear-gradient(left,  #666666 0%,#555555 35%,#444444 70%,#373737 100%);
	background: -ms-linear-gradient(left,  #666666 0%,#555555 35%,#444444 70%,#373737 100%);
	background: linear-gradient(to right,  #666666 0%,#555555 35%,#444444 70%,#373737 100%);
}
#footer.dark .separator-2:after {
	background: #444444;
	background: -moz-linear-gradient(left,  #444444 0%, #393939 35%, #323232 70%, #222222 100%);
	background: -webkit-gradient(linear, left top, right top, color-stop(0%,#444444), color-stop(35%,#393939), color-stop(70%,#323232), color-stop(100%,#222222));
	background: -webkit-linear-gradient(left,  #444444 0%,#393939 35%,#323232 70%,#222222 100%);
	background: -o-linear-gradient(left,  #444444 0%,#393939 35%,#323232 70%,#222222 100%);
	background: -ms-linear-gradient(left,  #444444 0%,#393939 35%,#323232 70%,#222222 100%);
	background: linear-gradient(to right,  #444444 0%,#393939 35%,#323232 70%,#222222 100%);
}
.light-translucent-bg .separator-2:after,
.two-col-translucent-bg .container > .row > div .separator-2:after,
.dark.separator-2:after {
	background: rgba(0, 0, 0, 0.5);
	background: -moz-linear-gradient(left,  rgba(0, 0, 0, 0.5) 0%, rgba(232, 232, 232, 0.4) 35%, rgba(232, 232, 232, 0.2) 70%, rgba(0, 0, 0, 0.0) 100%);
	background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(0, 0, 0, 0.5)), color-stop(35%,rgba(232, 232, 232, 0.4)), color-stop(70%,rgba(232, 232, 232, 0.2)), color-stop(100%,rgba(0, 0, 0, 0.0)));
	background: -webkit-linear-gradient(left,  rgba(0, 0, 0, 0.5) 0%,rgba(232, 232, 232, 0.4) 35%,rgba(232, 232, 232, 0.2) 70%,rgba(0, 0, 0, 0.0) 100%);
	background: -o-linear-gradient(left,  rgba(0, 0, 0, 0.5) 0%,rgba(232, 232, 232, 0.4) 35%,rgba(232, 232, 232, 0.2) 70%,rgba(0, 0, 0, 0.0) 100%);
	background: -ms-linear-gradient(left,  rgba(0, 0, 0, 0.5) 0%,rgba(232, 232, 232, 0.4) 35%,rgba(232, 232, 232, 0.2) 70%,rgba(0, 0, 0, 0.0) 100%);
	background: linear-gradient(to right,  rgba(0, 0, 0, 0.5) 0%,rgba(232, 232, 232, 0.4) 35%,rgba(232, 232, 232, 0.2) 70%,rgba(0, 0, 0, 0.0) 100%);
}
.default-bg .separator-2:after,
.default-translucent-bg .separator-2:after,
.dark-translucent-bg .separator-2:after,
.light.separator-2:after {
	background: rgba(255, 255, 255, 0.5);
	background: -moz-linear-gradient(left,  rgba(255, 255, 255, 0.5) 0%, rgba(232, 232, 232, 0.4) 35%, rgba(232, 232, 232, 0.2) 70%, rgba(255, 255, 255, 0.0) 100%);
	background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255, 255, 255, 0.5)), color-stop(35%,rgba(232, 232, 232, 0.4)), color-stop(70%,rgba(232, 232, 232, 0.2)), color-stop(100%,rgba(255, 255, 255, 0.0)));
	background: -webkit-linear-gradient(left,  rgba(255, 255, 255, 0.5) 0%,rgba(232, 232, 232, 0.4) 35%,rgba(232, 232, 232, 0.2) 70%,rgba(255, 255, 255, 0.0) 100%);
	background: -o-linear-gradient(left,  rgba(255, 255, 255, 0.5) 0%,rgba(232, 232, 232, 0.4) 35%,rgba(232, 232, 232, 0.2) 70%,rgba(255, 255, 255, 0.0) 100%);
	background: -ms-linear-gradient(left,  rgba(255, 255, 255, 0.5) 0%,rgba(232, 232, 232, 0.4) 35%,rgba(232, 232, 232, 0.2) 70%,rgba(255, 255, 255, 0.0) 100%);
	background: linear-gradient(to right,  rgba(255, 255, 255, 0.5) 0%,rgba(232, 232, 232, 0.4) 35%,rgba(232, 232, 232, 0.2) 70%,rgba(255, 255, 255, 0.0) 100%);
}
/* Medium devices (desktops, tablets, 992px and up) */
@media (min-width: 992px) { 
	.two-col-translucent-bg .container > .row > div + div .separator-2:after {
		background: rgba(255, 255, 255, 0.5);
		background: -moz-linear-gradient(left,  rgba(255, 255, 255, 0.5) 0%, rgba(232, 232, 232, 0.4) 35%, rgba(232, 232, 232, 0.2) 70%, rgba(255, 255, 255, 0.0) 100%);
		background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255, 255, 255, 0.5)), color-stop(35%,rgba(232, 232, 232, 0.4)), color-stop(70%,rgba(232, 232, 232, 0.2)), color-stop(100%,rgba(255, 255, 255, 0.0)));
		background: -webkit-linear-gradient(left,  rgba(255, 255, 255, 0.5) 0%,rgba(232, 232, 232, 0.4) 35%,rgba(232, 232, 232, 0.2) 70%,rgba(255, 255, 255, 0.0) 100%);
		background: -o-linear-gradient(left,  rgba(255, 255, 255, 0.5) 0%,rgba(232, 232, 232, 0.4) 35%,rgba(232, 232, 232, 0.2) 70%,rgba(255, 255, 255, 0.0) 100%);
		background: -ms-linear-gradient(left,  rgba(255, 255, 255, 0.5) 0%,rgba(232, 232, 232, 0.4) 35%,rgba(232, 232, 232, 0.2) 70%,rgba(255, 255, 255, 0.0) 100%);
		background: linear-gradient(to right,  rgba(255, 255, 255, 0.5) 0%,rgba(232, 232, 232, 0.4) 35%,rgba(232, 232, 232, 0.2) 70%,rgba(255, 255, 255, 0.0) 100%);
	}
}
.separator-3:after {
	height: 1px;
	background: #d1d1d1;
	background: -moz-linear-gradient(right,  rgba(0, 0, 0, 0.18) 0%, rgba(0, 0, 0, 0.12) 35%, rgba(0, 0, 0, 0.05) 70%, rgba(0, 0, 0, 0) 100%);
	background: -webkit-gradient(linear, right top, left top, color-stop(0%,rgba(0, 0, 0, 0.18)), color-stop(35%,rgba(0, 0, 0, 0.12)), color-stop(70%,rgba(0, 0, 0, 0.05)), color-stop(100%,rgba(0, 0, 0, 0)));
	background: -webkit-linear-gradient(right,  rgba(0, 0, 0, 0.18) 0%,rgba(0, 0, 0, 0.12) 35%,rgba(0, 0, 0, 0.05) 70%,rgba(0, 0, 0, 0) 100%);
	background: -o-linear-gradient(right,  rgba(0, 0, 0, 0.18) 0%,rgba(0, 0, 0, 0.12) 35%,rgba(0, 0, 0, 0.05) 70%,rgba(0, 0, 0, 0) 100%);
	background: -ms-linear-gradient(right,  rgba(0, 0, 0, 0.18) 0%,rgba(0, 0, 0, 0.12) 35%,rgba(0, 0, 0, 0.05) 70%,rgba(0, 0, 0, 0) 100%);
	background: linear-gradient(to left,  rgba(0, 0, 0, 0.18) 0%,rgba(0, 0, 0, 0.12) 35%,rgba(0, 0, 0, 0.05) 70%,rgba(0, 0, 0, 0) 100%);
	position: absolute;
	bottom: -1px;
	left: 0px;
	content: "";
	width: 100%;
}
.dark-bg .separator-3:after {
	background: #666666;
	background: -moz-linear-gradient(right,  #666666 0%, #555555 35%, #444444 70%, #373737 100%);
	background: -webkit-gradient(linear, right top, left top, color-stop(0%,#666666), color-stop(35%,#555555), color-stop(70%,#444444), color-stop(100%,#373737));
	background: -webkit-linear-gradient(right,  #666666 0%,#555555 35%,#444444 70%,#373737 100%);
	background: -o-linear-gradient(right,  #666666 0%,#555555 35%,#444444 70%,#373737 100%);
	background: -ms-linear-gradient(right,  #666666 0%,#555555 35%,#444444 70%,#373737 100%);
	background: linear-gradient(to left,  #666666 0%,#555555 35%,#444444 70%,#373737 100%);
}
#footer.dark .separator-3:after {
	background: #444444;
	background: -moz-linear-gradient(right,  #444444 0%, #393939 35%, #323232 70%, #222222 100%);
	background: -webkit-gradient(linear, right top, left top, color-stop(0%,#444444), color-stop(35%,#393939), color-stop(70%,#323232), color-stop(100%,#222222));
	background: -webkit-linear-gradient(right,  #444444 0%,#393939 35%,#323232 70%,#222222 100%);
	background: -o-linear-gradient(right,  #444444 0%,#393939 35%,#323232 70%,#222222 100%);
	background: -ms-linear-gradient(right,  #444444 0%,#393939 35%,#323232 70%,#222222 100%);
	background: linear-gradient(to left,  #444444 0%,#393939 35%,#323232 70%,#222222 100%);
}
.light-translucent-bg .separator-3:after,
.two-col-translucent-bg .container > .row > div .separator-3:after,
.dark.separator-3:after {
	background: rgba(0, 0, 0, 0.5);
	background: -moz-linear-gradient(right,  rgba(0, 0, 0, 0.5) 0%, rgba(232, 232, 232, 0.4) 35%, rgba(232, 232, 232, 0.2) 70%, rgba(0, 0, 0, 0.0) 100%);
	background: -webkit-gradient(linear, right top, left top, color-stop(0%,rgba(0, 0, 0, 0.5)), color-stop(35%,rgba(232, 232, 232, 0.4)), color-stop(70%,rgba(232, 232, 232, 0.2)), color-stop(100%,rgba(0, 0, 0, 0.0)));
	background: -webkit-linear-gradient(right,  rgba(0, 0, 0, 0.5) 0%,rgba(232, 232, 232, 0.4) 35%,rgba(232, 232, 232, 0.2) 70%,rgba(0, 0, 0, 0.0) 100%);
	background: -o-linear-gradient(right,  rgba(0, 0, 0, 0.5) 0%,rgba(232, 232, 232, 0.4) 35%,rgba(232, 232, 232, 0.2) 70%,rgba(0, 0, 0, 0.0) 100%);
	background: -ms-linear-gradient(right,  rgba(0, 0, 0, 0.5) 0%,rgba(232, 232, 232, 0.4) 35%,rgba(232, 232, 232, 0.2) 70%,rgba(0, 0, 0, 0.0) 100%);
	background: linear-gradient(to left,  rgba(0, 0, 0, 0.5) 0%,rgba(232, 232, 232, 0.4) 35%,rgba(232, 232, 232, 0.2) 70%,rgba(0, 0, 0, 0.0) 100%);
}
.default-bg .separator-3:after,
.default-translucent-bg .separator-3:after,
.dark-translucent-bg .separator-3:after,
.light.separator-3:after {
	background: rgba(255, 255, 255, 0.5);
	background: -moz-linear-gradient(right,  rgba(255, 255, 255, 0.5) 0%, rgba(232, 232, 232, 0.4) 35%, rgba(232, 232, 232, 0.2) 70%, rgba(255, 255, 255, 0.0) 100%);
	background: -webkit-gradient(linear, right top, left top, color-stop(0%,rgba(255, 255, 255, 0.5)), color-stop(35%,rgba(232, 232, 232, 0.4)), color-stop(70%,rgba(232, 232, 232, 0.2)), color-stop(100%,rgba(255, 255, 255, 0.0)));
	background: -webkit-linear-gradient(right,  rgba(255, 255, 255, 0.5) 0%,rgba(232, 232, 232, 0.4) 35%,rgba(232, 232, 232, 0.2) 70%,rgba(255, 255, 255, 0.0) 100%);
	background: -o-linear-gradient(right,  rgba(255, 255, 255, 0.5) 0%,rgba(232, 232, 232, 0.4) 35%,rgba(232, 232, 232, 0.2) 70%,rgba(255, 255, 255, 0.0) 100%);
	background: -ms-linear-gradient(right,  rgba(255, 255, 255, 0.5) 0%,rgba(232, 232, 232, 0.4) 35%,rgba(232, 232, 232, 0.2) 70%,rgba(255, 255, 255, 0.0) 100%);
	background: linear-gradient(to left,  rgba(255, 255, 255, 0.5) 0%,rgba(232, 232, 232, 0.4) 35%,rgba(232, 232, 232, 0.2) 70%,rgba(255, 255, 255, 0.0) 100%);
}
/* Medium devices (desktops, tablets, 992px and up) */
@media (min-width: 992px) { 
	.two-col-translucent-bg .container > .row > div + div .separator-3:after {
		background: rgba(255, 255, 255, 0.5);
		background: -moz-linear-gradient(right,  rgba(255, 255, 255, 0.5) 0%, rgba(232, 232, 232, 0.4) 35%, rgba(232, 232, 232, 0.2) 70%, rgba(255, 255, 255, 0.0) 100%);
		background: -webkit-gradient(linear, right top, left top, color-stop(0%,rgba(255, 255, 255, 0.5)), color-stop(35%,rgba(232, 232, 232, 0.4)), color-stop(70%,rgba(232, 232, 232, 0.2)), color-stop(100%,rgba(255, 255, 255, 0.0)));
		background: -webkit-linear-gradient(right,  rgba(255, 255, 255, 0.5) 0%,rgba(232, 232, 232, 0.4) 35%,rgba(232, 232, 232, 0.2) 70%,rgba(255, 255, 255, 0.0) 100%);
		background: -o-linear-gradient(right,  rgba(255, 255, 255, 0.5) 0%,rgba(232, 232, 232, 0.4) 35%,rgba(232, 232, 232, 0.2) 70%,rgba(255, 255, 255, 0.0) 100%);
		background: -ms-linear-gradient(right,  rgba(255, 255, 255, 0.5) 0%,rgba(232, 232, 232, 0.4) 35%,rgba(232, 232, 232, 0.2) 70%,rgba(255, 255, 255, 0.0) 100%);
		background: linear-gradient(to left,  rgba(255, 255, 255, 0.5) 0%,rgba(232, 232, 232, 0.4) 35%,rgba(232, 232, 232, 0.2) 70%,rgba(255, 255, 255, 0.0) 100%);
	}
}
.footer-content .separator-2,
.footer-content .separator-3 {
	margin-bottom: 25px;
}
.object-non-visible {
	.opacity(0);
}
.object-visible {
	opacity: 1 !important;
	filter: alpha(opacity=100) !important;
}
/* Targeting only Firefox for smoothest animations */
@-moz-document url-prefix() {
	.object-visible {
		-webkit-transition: opacity 0.6s ease-in-out;
		-moz-transition: opacity 0.6s ease-in-out;
		-o-transition: opacity 0.6s ease-in-out;
		-ms-transition: opacity 0.6s ease-in-out;
		transition: opacity 0.6s ease-in-out;
	}
}

/* Miscellaneous
---------------------------------- */
.pl-5 {
	padding-left: 5px;
}
.pl-10 {
	padding-left: 10px;
}
.pl-20 {
	padding-left: 20px;
}
.pr-5 {
	padding-right: 5px;
}
.pr-10 {
	padding-right: 10px;
}
.pr-20 {
	padding-right: 20px;
}
.p-20 {
	padding: 20px;
}
.p-30 {
	padding: 30px;
}
.p-40 {
	padding: 40px;
}
.ph-20 {
	padding-left: 20px;
	padding-right: 20px;
}
.ph-30 {
	padding-left: 30px;
	padding-right: 30px;
}
.ph-40 {
	padding-left: 40px;
	padding-right: 40px;
}
.pv-20 {
	padding-top: 20px;
	padding-bottom: 20px;
}
.pv-30 {
	padding-top: 30px;
	padding-bottom: 30px;
}
.pv-40 {
	padding-top: 40px;
	padding-bottom: 40px;
}
.mt-5 {
	margin-top: 5px;
}
.mb-5 {
	margin-bottom: 5px;
}
.mt-10 {
	margin-top: 10px;
}
.mb-10 {
	margin-bottom: 10px;
}
.mt-20 {
	margin-top: 20px;
}
.mb-20 {
	margin-bottom: 20px;
}
.space-top {
	padding-top: 20px;
}
.space-bottom {
	padding-bottom: 20px;
}
.margin-clear {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}
.padding-top-clear {
	padding-top: 0 !important;
}
.padding-bottom-clear {
	padding-bottom: 0 !important;
}
.padding-ver-clear {
	padding-bottom: 0 !important;
	padding-top: 0 !important;
}
.padding-hor-clear {
	padding-right: 0 !important;
	padding-left: 0 !important;
}
.border-bottom-clear {
	border-bottom: none !important;
}
.border-top-clear {
	border-top: none !important;
}
.border-clear {
	border: none !important;
}
.circle {
	-webkit-border-radius: 100%!important;
	-moz-border-radius: 100%!important;
	border-radius: 100%!important;
}
/* Small devices (tablets, phones less than 767px) */
@media (max-width: 767px) { 
	.text-center-xs {
		text-align: center;
	}
}